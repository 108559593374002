<template>
    <div class="my-aq-list">
        <div class="my-aq-item" v-for="(item, index) in dataList" :key="index">
            <div class="title-wrapper">
                <router-link :to="`/ask/list?IllCate=${item.illnesscategoryid}`" class="category" v-text="'#'+item.illnesscategorycode"></router-link>
                <router-link :to="'/ask/detail/'+item.askid" class="title" v-text="item.title"></router-link>
            </div>
            <div class="operate">
                <router-link :to="'/ask/detail/'+item.askid" class="fb-count" v-text="item.answerList.length + '條回復'"></router-link>
                <!-- <a-popconfirm
                    title="確定刪除？"
                    ok-text="確定"
                    cancel-text="取消"
                    @confirm="doDelItem(item.askid)"
                >
                    <span class="delete">刪除</span>
                </a-popconfirm> -->
            </div>
        </div>
        <loading v-if="loading"/>
        <split-page v-if="visible" :initData="{pageNo, pageSize, total}" :onPageChanged="onPageChanged"/>
    </div>
</template>

<script>

    import my_ask from '@/views/mixins/my_ask'
    import Loading from '@/layouts/Loading.vue'
    import SplitPage from '@/layouts/SplitPage.vue'
	export default {
        mixins: [
            my_ask
        ],
        components: {
            SplitPage,
            Loading,
        },
		data() {
            return {
                clientId: this.$store.getters.clientId,
                visible: false,
                pageNo: 1,
                pageSize: 10,
                total: 0,
                loading: false,
                dataList: [],
            }
        },
		mounted: function(){
            this.pageNo = this.$route.query.page || 1
            this.onRefresh()
		},
        methods: {
            onRefresh: function(){
                this.loading = true
                this.loadData({
                    clientid: this.clientId,
                    pageNo: this.pageNo, 
                    pageSize: this.pageSize
                }, (data, error)=>{
                    if(error) {
                        this.loading = false
                        this._toast.warning(this, error)
                        return
                    }
                    this.loading = false
                    this.dataList = data.list
                    this.total = data.total
                    this.visible = this.total > 0
                })
            },
            doDelItem: function(askid){

            },
            onPageChanged: function(page){
                this.pageNo = page
                this.$router.push('/user/aq-news/question?page='+page)
                this.onRefresh()
            }
        }
	};
</script>

<style lang="scss" scoped>
    .my-aq-list {
        display: flex;
        flex-direction: column;
        padding: 13px 30px 0px 13px;
        box-sizing: border-box;
        padding-bottom: 60px;
        position: relative;
        min-height: 673px;
    }

    .my-aq-list > .my-aq-item {
        display: flex;
        flex-direction: row;
        height: 60px;
        align-items: center;
        box-sizing: border-box;
        border-bottom: 1px solid #e8e8e8;
    }

    .my-aq-list > .my-aq-item .title-wrapper {
        flex: 1;
    }

    .my-aq-list > .my-aq-item .category {
        display: inline;
        color: #FF7800;
        font-size: 18px;
        font-weight: bold;
        margin-right: 10px;
    }

    .my-aq-list > .my-aq-item .title {
        display: inline;
        font-size: 18px;
        color: #231F20;
        font-weight: bold;
    }

    .my-aq-list > .my-aq-item > .operate {
        margin-left: 10px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }

    .my-aq-list > .my-aq-item > .operate .fb-count {
        padding-left: 25px;
        height: 15px;
        line-height: 15px;
        color: #969696;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        margin-right: 28px;
        cursor: pointer;
    }

    .my-aq-list > .my-aq-item > .operate .fb-count::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 17px;
        height: 15px;
        background-image: url(../../../../assets/imgs/pc/user/img_xinxi@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .my-aq-list > .my-aq-item > .operate .delete {
        padding-left: 21px;
        height: 16px;
        line-height: 16px;
        color: #969696;
        font-size: 14px;
        font-weight: 400;
        position: relative;
        margin-right: 12px;
        cursor: pointer;
    }

    .my-aq-list > .my-aq-item > .operate .delete::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 14px;
        height: 16px;
        background-image: url(../../../../assets/imgs/pc/user/img_del@2x.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    /* 我的问答 end */


	.split-pages-container {
		position: absolute;
		bottom: 20px;
		right: 20px;
		display: flex;
		flex-direction: row;
		margin-top: 25px;
		justify-content: flex-end;
	}

	.split-pages-container > .split-pages {
		display: flex;
		flex-direction: row;
	}

	.split-pages-container > .split-pages > .split-page {
		display: inline-block;
		width: 22px;
		height: 22px;
		border-radius: 50%;
		color: #969696;
		font-size: 14px;
		line-height: 22px;
		text-align: center;
		font-weight: 400;
		margin-right: 20px;
		background-color: #DDDDDD;
		cursor: pointer;
	}

	.split-pages-container > .split-pages > .split-page.more {
		line-height: 16px;
	}

	.split-pages-container > .split-pages > .split-page:hover,
	.split-pages-container > .split-pages > .split-page.current {
		color: #fff;
		background-color: #36C4D0;
		font-weight: bold;
	}

	.split-pages-container > .btn-page-prev,
	.split-pages-container > .btn-page-next {
		height: 20px;
		width: 56px;
		text-align: center;
		line-height: 20px;
		font-weight: 300;
		color: #636363;
		background-color: #DDDDDD;
		border-radius: 4px;
		font-weight: 400;
		font-size: 14px;
		cursor: pointer;
	}

	.split-pages-container > .btn-page-next {
		margin-left: 24px;
		color: #231F20;
	}
</style>
